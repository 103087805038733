@import '~bootstrap';
body {
    background-color: lightgray;
}
.blink {
    font-size: 14px;
    animation: blink 2s steps(1, end) infinite;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
